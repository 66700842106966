<template>
  <div class="flex flex-col flex-grow">
    <div class="flex flex-row p-3 mt-10 mb-5 rounded-sm bg-onboarding-200">
      <div class="w-10 mr-5">
        <vector :src="assets.icons.sm.shieldWhite" />
      </div>

      <div class="mt-3 text-left text-white text-9">
        Your information is highly secured. We’re registered and regulated by
        the CBN. Information requested will only be used to request your bank
        statement from your bank.
      </div>
    </div>

    <h5 class="mb-3 font-normal text-13">Bank Account Information</h5>

    <form class="flex flex-col flex-grow" @submit.prevent="submit">
      <!-- <form-group
        name="bank_id"
        :form="form"
        v-model="form.data.bank_id.value"
        disabled
      >
        Bank Name
      </form-group> -->

      <form-group
        name="account_no"
        :form="form"
        v-model="form.data.account_no.value"
      >
        Account No
      </form-group>

      <form-group
        type="phone"
        name="phone"
        :form="form"
        v-model="form.data.phone.value"
      >
        Phone Number Attached To Bank Account
      </form-group>

      <template v-if="getFormError(form)">
        <div class="alert alert-red-soft mb-5">
          <span class="alert-icon">!</span>
          <span>{{ getFormError(form) }}</span>
        </div>
      </template>

      <div class="flex gap-4 mt-auto">
        <button
          type="button"
          class="relative btn btn-gray-soft btn-md"
          :disabled="form.loading"
          @click="back"
        >
          <span>Back</span>
          <i
            class="absolute inline-flex left-20px top-1/2"
            style="transform: translateY(-50%);"
          >
            <ion-icon name="arrow-back-outline" />
          </i>
        </button>
        <button
          type="submit"
          class="relative w-full btn btn-blue btn-md"
          :disabled="form.loading || isUploaded"
        >
          <sm-loader v-if="form.loading" class="sm-loader-white" />
          <span v-else>Proceed</span>
          <i
            class="absolute inline-flex right-20px top-1/2"
            style="transform: translateY(-50%);"
          >
            <ion-icon name="arrow-forward-outline" />
          </i>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'StatementRequest',
  props: {
    bank: {
      type: String,
      required: true
    },
    isUploaded: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      form: this.$options.basicForm([
        { name: 'account_no', rules: 'required|number|length:10' },
        { name: 'bank_id', rules: 'required' },
        { name: 'phone', rules: 'required|phone' }
      ])
    };
  },
  computed: {
    ...mapState(['mbsAllowedBanks']),
    bankId() {
      return this.mbsAllowedBanks.find((bank) => bank.name === this.bank)?.id;
    },
  },
  mounted() {
    this.mapData();
  },
  methods: {
    back() {
      this.$emit('select-different-method');
    },
    mapData() {
      this.form.data.bank_id.value = this.bankId;
    },
    async submit() {
      if (!this.validateForm(this.form)) return false;
      this.form.error = null;
      this.form.setLoading();

      await this.sendRequest('personal.onboarding.documents.mbs', {
        data: this.getFormData(this.form),
        success: () => {
          this.$emit('request-statement', this.getFormData(this.form));
        },
        error: (error) => {
          this.form.error = error;
          this.mapFormErrors(this.form, error?.response?.data?.errors);
        }
      });
      this.form.setLoading(false);
    }
  }
};
</script>
